* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}

#root {
  overflow: hidden;
}
.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

canvas {
  background: linear-gradient(
    140deg,
    rgba(255, 249, 195, 1) 0%,
    rgba(255, 249, 195, 0.9) 50%,
    rgba(255, 249, 195, 1) 100%
  );
  overflow: hidden;
}

#tsparticles {
  position: fixed;
  z-index: -1;
}
.main-container {
  text-align: center;
  overflow: hidden;
  top: 80px;
  width: 60%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: 98vh;

  border-radius: 30px;
  margin: auto;
  max-height: calc(100vh);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiFormControl-root {
  width: 96% !important;
margin: auto !important;
height: fit-content !important;

  border-radius: 10px !important;

}

.MuiInputBase-colorPrimary {
  color: #682219 !important;
  width: 100% !important;
}

.MuiFilledInput-root::after {
  background: rgba(216, 169, 106, 0.8) !important;
  color: #fff !important;
  border-bottom: 2px solid #682219 !important;
}
.MuiFilledInput-root {
  border-bottom: 0px solid #682219 !important;
  background: rgba(216, 169, 106, 0.8) !important;
  color: #682219 !important;
  border-radius: 30px !important;
  text-align: center !important;
 
 
}
.MuiFilledInput-root::before {
  border-bottom: 0px solid #682219 !important;

  color: #682219 !important;
  border-radius: 30px !important;
}
.MuiFormLabel-root {
  color: #682219 !important;
}
.MuiDialog-paper {
  background: rgba(255, 105, 180, 0.3) !important;
  color: #682219 !important;
  border-radius: 10px !important;
}
.MuiInputLabel-root {
  text-align: center !important;
  width: 100% !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
 display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50%;
}
.MuiInputLabel-shrink {
  margin: auto !important;
  width: 150px !important;
  left: calc(50% - 86px) !important;
  transform-origin: top !important;
}
#dialog-title {
  color: #682219 !important;
}
@media only screen and (max-width: 950px) {
  .main-container {
    top: 110px;
    width: 86vw;
    /* Fallback for browsers that do not support Custom Properties */
    height: 95vh !important;
    background: rgba(0, 0, 0, 0);
    border-radius: 0px;
    margin: auto;
    max-height: 100vh;
  }
  .MuiFormControl-root {
    width: 96% !important;


    border-radius: 10px !important;
  }

}
