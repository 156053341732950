#login-button {
  border: 2px solid #682219 !important;
  background: rgba(216, 169, 106, 0.8) !important;
  color: #682219 !important;
  border-radius: 20px !important;
  width: 10% !important;
  min-width: 200px !important;
  height: 12% !important;
  margin-top: 5%;
  margin-bottom: 2%;
  min-height: 50px !important;
  max-width: 90% !important;
  background: rgba(0, 0, 0, 0.8);
  font-weight: bold !important;
}
.login-form {
  width: 100%;
  height: 82%;
  border: 2px solid #682219;
  padding: 3%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-header{
  height: 15%;
  width: 100%;
}
.form_group {
  margin: auto;
  height: 50%;
  justify-content: center;
  align-items: center;
 display: flex;
  flex-direction: column;

  width: 100%;

}
#registerHint {
  color: #682219;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
@media only screen and (max-width: 950px) {

#login-button {
  border: 2px solid #682219 !important;
  background: rgba(216, 169, 106, 0.8) !important;
  color: #682219 !important;
  border-radius: 20px !important;
  width: 10% !important;
  min-width: 200px !important;
  height: 19% !important;
  margin-top: 7%;
  margin-bottom: 7%;
  min-height: 50px !important;
  max-width: 90% !important;
  background: rgba(0, 0, 0, 0.8);
  font-weight: bold !important;
}
}



