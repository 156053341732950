.einkaufsliste-container {
  width: 100%;
  height: 82%;
  border: 2px solid #682219;

  border-radius: 30px;
}
.einkaufsliste-header {
  width: 100%;

  font-size: 2rem;
  font-weight: 700;
  position: relative;
  height: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner {
  position: absolute;
 bottom: 0;
margin: auto;
}
.image{
  width: 90%;
  height: 100%;
  margin: auto;
  left: 5%;
  z-index: -1;
  transform: translateY(15px);
}
.newItem-form_group {
  display: flex !important;
  flex-direction: column !important;
padding: 1% !important;
  justify-content: left;
  align-items: left;
  width: 98%;
  height: 100%;
}
.newItem-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 25%;
}

#newItem-button {
  margin: 1% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border: 2px solid #682219 !important;
  color: #682219 !important;
  border-radius: 20px !important;
  width: 10% !important;
  min-width: 200px !important;
  height: 25% !important;
  min-height: 40px !important;
  max-width: 90% !important;
  background: rgba(216, 169, 106, 0.8) !important;
  font-weight: bold !important;
}
.swipeable-list {
  width: 100%;
  height: 75% !important;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll !important;


  border-radius: 30px;
  background: rgba(216, 169, 106, 0.8);
  padding: 2%;
}
.swipeable-list-item {
  width: 100%;
  height: fit-content;
  border: 2px solid #682219;
  padding: 1%;
  padding-left: 12px;
  border-radius: 30px;
  margin-bottom: 2%;
  background: rgba(216, 169, 106, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.item-name {
  font-size: 1.2rem;
  font-weight: 700;
  color: #682219;
  text-align: left;
}
.item-beschreibung {
  font-size: 1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.swipeable-list-item__trailing-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ActionContent {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
}
.ItemColumnCentered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 950px) {
  .swipeable-list {
    width: 100%;
    height: 70% !important;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll !important;


    padding: 2%;
  }
  .newItem-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 30%;
  }
}
