.title {
  width: 60%;
  height: fit-content;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Homemade Apple", cursive;
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 30px;

  color: #682219;
  background: rg;
  margin: auto;
}

@media only screen and (max-width: 950px) {
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 30px;
    height: fit-content;

    color: #682219;
  }
}
